<template>
  <div class="about" id="welcome">
    <section class="about__welcome">
      <div class="about__welcome-container content-container">
        <div class="about__welcome-text">
          <h1>{{ $t('pages.about.welcome.title') }}</h1>

          <p>{{ $t('pages.about.welcome.paragraph') }}</p>
        </div>

        <div class="about__welcome-image">
          <ImageApp
              :path="'about__welcome-meat.png'"
              :alt="$t('pages.about.welcome.title')"
          />
        </div>
      </div>
    </section>

    <section class="about__information">
      <div class="about__information-container">
        {{ $t('pages.about.information') }}
      </div>
    </section>

    <section class="about__advantages">
      <div class="about__advantages-container content-container">
        <h2 v-html="$t('pages.about.advantages.title')"></h2>

        <ul>
          <li>
            <div class="about__advantages-icon">
              <span class="icon icon__chart"></span>
            </div>

            {{ $t('pages.about.advantages.list.chart') }}
          </li>

          <li>
            <div class="about__advantages-icon">
              <span class="icon icon__earth"></span>
            </div>

            {{ $t('pages.about.advantages.list.earth') }}
          </li>

          <li>
            <div class="about__advantages-icon">
              <span class="icon icon__profile"></span>
            </div>

            {{ $t('pages.about.advantages.list.profile') }}
          </li>

          <li>
            <div class="about__advantages-icon">
              <span class="icon icon__weight"></span>
            </div>

            {{ $t('pages.about.advantages.list.weight') }}
          </li>

          <li>
            <div class="about__advantages-icon">
              <span class="icon icon__cart"></span>
            </div>

            {{ $t('pages.about.advantages.list.cart') }}
          </li>
        </ul>
      </div>
    </section>

    <section class="about__bottom">
      <div class="about__bottom-container">
        {{ $t('pages.about.bottom-text') }}
      </div>
    </section>
  </div>
</template>

<script>
import ImageApp from "@/components/ImageApp";

export default {
  name: "About",
  components: {ImageApp}
}
</script>

<style lang="scss">
.about {
  padding-top: 220px;
  background: url("../assets/image/header.png") no-repeat top center;
  background-size: contain;

  &__welcome {
    padding: 0 0 50px 0;
    position: relative;
    background: transparent url("../assets/image/background__products_meat.png") no-repeat 0 35px;
    background-size: contain;
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 0 0 80px 0;
    }

    @media (min-width: 1024px) {
      min-height: 540px;
      padding: 0;
    }

    &-container {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    & h1 {
      font: 700 50px "Lato-Bold", sans-serif;
      color: #ff2e2b;
      padding: 15px 0 20px;
      position: relative;
      z-index: 1;

      @media (min-width: 550px) {
        font: 700 60px "Lato-Bold", sans-serif;
      }

      @media (min-width: 768px) {
        font: 700 70px "Lato-Bold", sans-serif;
        padding: 50px 0 20px;
      }

      @media (min-width: 1024px) {
        font: 700 80px "Lato-Bold", sans-serif;
        padding: 80px 0 35px;
      }
    }

    & p {
      font: 400 24px "Lato-Regular", sans-serif;
      color: #000;
      position: relative;
      z-index: 1;

      @media (min-width: 550px) {
        font: 400 26px "Lato-Regular", sans-serif;
      }

      @media (min-width: 768px) {
        max-width: 410px;
        font: 400 28px "Lato-Regular", sans-serif;
      }

      @media (min-width: 1024px) {
        font: 400 36px "Lato-Regular", sans-serif;
      }
    }

    &-image {
      display: none;

      @media (min-width: 768px) {
        display: block;
        margin: 65px 0 0 30px;
        max-width: 400px;
        width: 55%;
      }

      @media (min-width: 1024px) {
        max-width: 646px;
      }

      @media (min-width: 1200px) {

      }
    }
  }

  &__information {
    min-height: 590px;
    background: url("../assets/image/about__information.png") no-repeat top center;
    background-size: cover;
    padding: 90px 20px;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      min-height: 400px;
      background-color: rgba(255, 255, 255, .8);
      border-radius: 10px;
      padding: 40px 25px;
      text-align: center;
      font: 400 24px "Lato-Regular", sans-serif;
      color: #000;

      @media (min-width: 550px) {
        font: 400 26px "Lato-Regular", sans-serif;
        padding: 40px 45px;
      }

      @media (min-width: 768px) {
        font: 400 28px "Lato-Regular", sans-serif;
      }

      @media (min-width: 1024px) {
        font: 400 36px "Lato-Regular", sans-serif;
        padding: 130px 35px 110px;
      }
    }
  }

  &__advantages {
    padding: 30px 0 0 0;

    &-container {
      max-width: 1150px;
    }

    & h2 {
      font: 400 24px "Lato-Regular", sans-serif;
      color: #000;
      text-align: center;
      margin-bottom: 30px;

      @media (min-width: 550px) {
        font: 400 26px "Lato-Regular", sans-serif;
      }

      @media (min-width: 768px) {
        font: 400 28px "Lato-Regular", sans-serif;
      }

      @media (min-width: 1024px) {
        font: 400 30px "Lato-Regular", sans-serif;
      }

      @media (min-width: 1200px) {
        font: 400 36px "Lato-Regular", sans-serif;
      }

      & span {
        display: block;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    & ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;

      @media (min-width: 550px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
      }

      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media (min-width: 1200px) {
        grid-gap: 115px;
      }

      & li {
        width: 130px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font: 400 18px "Lato-Regular", sans-serif;
        color: #000;
        text-align: center;
        margin: 0 auto;
      }
    }

    &-icon {
      width: 101px;
      height: 101px;
      border: 1px solid #000000;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  &__bottom {
    padding: 100px 20px 50px;
    position: relative;
    overflow: hidden;
    background: url("../assets/image/village.png") no-repeat top center;
    background-size: 255px;

    @media (min-width: 1024px) {
      padding: 200px 0 25px;
      background-size: 555px;
    }

    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 140px;
      height: 60px;
      background: url("../assets/image/about__bottom-before.png") no-repeat center;
      background-size: contain;

      @media (min-width: 1024px) {
        bottom: 0;
        left: -200px;
        width: 400px;
        height: 185px;
      }

      @media (min-width: 1500px) {
        bottom: 0;
        left: -330px;
        width: 629px;
        height: 286px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 140px;
      height: 70px;
      background: url("../assets/image/about__bottom-after.png") no-repeat center;
      background-size: contain;

      @media (min-width: 1024px) {
        left: unset;
        right: -100px;
        width: 280px;
        height: 140px;
      }

      @media (min-width: 1500px) {
        right: -150px;
        width: 525px;
        height: 265px;
      }
    }

    &-container {
      max-width: 1050px;
      width: 100%;
      margin: 0 auto;
      font: 400 24px "Lato-Regular", sans-serif;
      color: #000;
      text-align: center;
      position: relative;
      z-index: 1;

      @media (min-width: 550px) {
        font: 400 26px "Lato-Regular", sans-serif;
      }

      @media (min-width: 768px) {
        font: 400 28px "Lato-Regular", sans-serif;
        max-width: 750px;
      }

      @media (min-width: 1024px) {
        font: 400 30px "Lato-Regular", sans-serif;
      }

      @media (min-width: 1200px) {
        font: 400 36px "Lato-Regular", sans-serif;
        max-width: 950px;
      }

      @media (min-width: 1500px) {
        max-width: 1050px;
      }
    }
  }
}
</style>